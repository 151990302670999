import React from 'react';

const HeroSection = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h1>Reliable 24/7 IT Assistance for Your PC and Laptop</h1>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="tel:+18008945705" className="btn-get-started scrollto">Call Now</a>
            </div>
          </div>
          {/* <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src="/path/to/your/image.jpg" className="img-fluid animated" alt="IT Assistance" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
