import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/emailsubscription');
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h4>IT Assist Experts</h4>
            <p>
              IT Assist Experts offers comprehensive IT solutions to ensure smooth and efficient business operations. Our expertise spans troubleshooting, infrastructure maintenance, and the implementation of cutting-edge technologies to enhance performance and security.
            </p>
          </div>
          <div className="col-md-3">
            <h4>Important Links</h4>
            <ul>
              <li><Link to="/aboutus">About Us</Link></li>
              <li><Link to="/privacy-policy">Privacy & Policy</Link></li>
              <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
              <li><Link to="/contactus">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Contact Details</h4>
            <address>
              <p><i className="bi bi-geo-alt-fill"></i> No6401 31ST ST S APT 112, SAINT PETERSBURG, FL 33712 US</p>
              <p><i className="bi bi-geo-alt-fill"></i> 2296 Henderson Mill Rd #116, Atlanta, GA 30345</p>
              <p><i className="bi bi-telephone-fill"></i> +1 (941) 553-5243</p>
              <p><i className="bi bi-envelope-fill"></i> support@itassistexperts.com</p>
            </address>
          </div>
          <div className="col-md-3">
            <h4>Newsletter</h4>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="email" className="form-control newsletter-input" placeholder="Enter your email here" />
              </div>
              <button type="submit" className="btn btn-primary newsletter-btn">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
