import React from 'react';

const ServicesSection = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Why Choose Us?</h2>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <img src="assets/img/ICON-TEAM.svg" className="img-fluid" alt="" />
              <h4><a href="">Your Very Own Dedicated IT Team</a></h4>
              <p>Expert IT professionals committed solely to your business, ensuring seamless operations, personalized solutions, and rapid tech issue resolutions.</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <img src="assets/img/ICON-BULLSEYE.svg" className="img-fluid" alt="" />
              <h4><a href="">Cutting-edge Technology</a></h4>
              <p>Advanced, forward-thinking solutions harnessing the latest technological innovations, driving efficiency, and setting industry standards for tomorrow’s challenges.</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <img src="assets/img/ICON-AUTOMATION.svg" className="img-fluid" alt="" />
              <h4><a href="">Service Automation</a></h4>
              <p>Streamlined, efficient processes powered by intelligent software, optimizing operations, reducing manual intervention, and ensuring consistent, high-quality outcomes.</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <img src="assets/img/ICON-RESPONSE.svg" className="img-fluid" alt="" />
              <h4><a href="">Quick Remote Response</a></h4>
              <p>Instantaneous support from afar, ensuring rapid solutions, minimizing disruptions, and maintaining peak performance, all at the click of a button.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
