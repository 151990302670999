import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
    <section class="inner-page">
      <div class="container">
        <h1>Comp Care Takers Squad Privacy & Policy</h1>
        <h2>Introduction</h2>
        <p>This privacy policy applies to the website compcaretakerscom.com, and to Comp Care Takers services Websites and applications (“Websites”). These Websites are owned and operated by Comp Care Takers LLC, a Delaware corporation doing business as Comp Care Takers, and its subsidiaries (“Comp Care Takers”). This privacy policy describes how Comp Care Takers collects and uses the personal information you provide on our Websites. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information.</p>
        <p>QuickFix Support delivers IT outsourcing services that allow companies to do what they do best. As their trusted IT advisor, QuickFix Support helps these employers contain IT costs, minimize employer-related risk and relieve the administrative burden of IT. In order for QuickFix Support to provide this vast array of services to its customers and their worksite employees, QuickFix Support must collect certain personal information regarding the employees of its customers.</p>
        <p>Protecting the privacy of users of QuickFix Support products and services is important to QuickFix Support. QuickFix Support makes use of any personal information you provide to QuickFix Support only for the purposes outlined in this policy.</p>
        <p>This policy does not apply to any information that you may provide directly to third parties including via third-party sites linked to the QuickFix Support.com Website or QuickFix Support’s service Websites. While QuickFix Support actively seeks to ensure that its suppliers and service providers maintain high standards regarding the protection of personal information collected on their Websites, QuickFix Support is not responsible for the privacy practices employed by such sites.</p>
        <h2>Information We Collect</h2>
        <p>The quickfixsupport.com Website collects identifying information such as, your name, company name, company address and other contact information. We, QuickFix, will never sell, rent, lend or trade this information in our possession to any other company for the purpose of contacting you regarding commercial services. If you are a QuickFix customer or worksite employee of a customer, we collect the information necessary to provide our services, collecting it either from you directly or from your worksite employer. This collection is limited to the purpose of providing the service for which the customer has engaged QuickFix. By voluntarily providing information or by accessing our Websites, you consent to the collection, use and disclosure of your personally identifiable information in accordance with this Privacy Policy.</p>
        <p>When you download and/or use our services, we automatically collect information on the type of device you use, operating system version and the device identifier (or "UDID"). We do not ask for, access or track any location-based information from your mobile device at any time while downloading or using our mobile apps or services.</p>
        <h2>Usage Tracking and Cookies</h2>
        <p>Technologies such as cookies, beacons, tags and scripts are used by QuickFix and our affiliates, analytics service providers and third party providers of IT services we have engaged. These technologies are used in analyzing trends, administering the site, tracking users’ movements around the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an aggregated basis and, in the case of registered users, on an individual basis, as well.</p>
        <p>In addition, we use cookies on certain pages of our sites. We may also offer certain features that are only available through the use of a cookie. Cookies enable you to enter your password less frequently during a session. Cookies can also help us provide you information that is targeted to your interests. Cookies are stored on your hard drive, not on our sites. Most cookies are "session cookies," meaning they are automatically deleted at the end of a session. We also use persistent cookies. A persistent cookie remains on your hard drive for an extended period of time and remembers your preferences. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on our sites, and you may be required to reenter your password more frequently during a session. Our affiliates, tracking utility company and service providers use session ID cookies to make it easier for you to navigate our site.</p>
        <p>As is true of most Websites, we gather certain information automatically and store it in log files. This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp and clickstream data.</p>
        <p>We use this information to analyze trends, administer the site, track users’ movements around the site and gather demographic information about our user base as a whole. Sometimes, this automatically collected data can be linked to personally identifiable information, unless you have registered with the site, or created an account on the Website.</p>
        <p>If you send us proposals or personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on our sites, we may collect and store such information in a file specific to you. If you submit questions via our sites, we may post your questions on the sites, and reprint them in various articles and customer communications. We will never include the name, contact or other identifiable information of people who submit questions, regardless of which channel we use to distribute content.</p>
        <p>We partner with a third party to either display advertising on our Website or to manage our advertising on other Websites. Our third party partner may use cookies or similar technologies in order to provide you with advertising based upon your browsing activities and interests.</p>
        <h2>Use of Information</h2>
        <p>We use personally identifiable information about you to improve our marketing and promotional efforts, statistically analyze site usage, improve our content product offerings, customize our sites' content, layout and services, and provide our services (if applicable).</p>
        <p>We may use the email address, mailing address and phone number we collect from you to respond to your inquiries and, to the extent permitted by applicable law, to contact you regarding administrative notices, new product offerings and communications relevant to your use of our sites. In some cases, we may use your personally identifiable information to inform you of new services, promotions and other matters targeted to your interests. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included at the bottom of those emails.</p>
        <p>We use information in the file we maintain specific to you, and other information we obtain from your current and past activities on our sites, to resolve disputes and troubleshoot problems. At times, we may look across multiple users to identify problems or resolve disputes, and we may evaluate information about you to look for users using multiple User IDs or aliases.</p>
        <h2>Children's Privacy</h2>
        <p>QuickFix’s Websites are intended for adults. We do not knowingly collect personal information from children under the age of 16. If we obtain knowledge that we have personally identifiable information about a child under 16 in retrievable form in our files, we will delete that information from our existing files. However, we will retain information from adult registered users about their dependents for purposes of administering benefits for their dependents, including dependents under the age of 16.</p>
        <h2>Customers and Worksite Employees of QuickFix</h2>
        <p>If you are a customer (also referred to as a worksite employer) or worksite employee serviced by QuickFix, you are a registered user and we may collect and maintain personally identifiable information including, but not limited to, name, address, phone number, Social Security number, taxpayer identification number, age, marital status, occupation, salary, benefits, dependent information, etc. through QuickFix service Websites or other means disclosed to you. This information will be used to provide our IT-related services, which may include sharing such information with your worksite employer to the extent permitted by applicable law.</p>
        <p>Once you create a username and password, you can review and change the information you submitted in your profile section. If your registration information changes, you must promptly update, change or delete your registration information by logging into your profile page or by contacting us at support@compcaretakerscom.com. We may retain your information, including personally identifiable information, for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes, enforce our agreements, manage risk, and/or fulfill our obligations to governments or insurance carriers. Further, such prior information is never completely removed from our databases due to technical constraints and the fact that we back up our systems. Therefore, you should not expect that all of your personally identifiable information will be completely removed from our databases in response to your requests.</p>
        <h2>Disclosure of Information</h2>
        <p>Unfortunately, due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this policy. By way of example (without limiting the foregoing): we may be forced to disclose information to the government or third parties under certain circumstances, such as to comply with a subpoena or similar legal process; we may use information when we believe in good faith that it is necessary to protect our rights, protect your safety or the safety of others; and we may respond to any other third party requests with your prior consent to do so.</p>
        <p>Third parties may unlawfully intercept or access transmissions or private communications. Therefore, although we employ industry-standard practices to protect your privacy, no data transmission over the internet can be guaranteed to be 100% secure. As a result, we do not promise, and you should not expect, that your personally identifiable information or private communications will always remain private.</p>
        <p>As a general proposition, we do not sell, rent, lend or trade any personally identifiable information about you to any third party. The following paragraphs describe some of the ways that personally identifiable information about you may be disclosed to third parties.</p>
        <h2>Legal Requests</h2>
        <p>We cooperate with law enforcement and other third parties to enforce laws, intellectual property rights and other rights. Therefore, local, state, federal, territorial, provincial and international law enforcement can request and may receive your personally identifiable information. By your use of the compcaretakerscom.com Website, QuickFix’s service Websites, or QuickFix's services (as applicable), you hereby accept these terms and you authorize us to disclose any information about you to law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements or other activity that is illegal or may expose us to legal liability.</p>
        <h2>Business Transactions</h2>
        <p>We reserve the right to transfer personally identifiable information as part of a sale or transfer of all or a relevant portion of our business or assets. Any such sale would be subject to appropriate privacy protections for you. You will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your personally identifiable information, as well as any choices you may have regarding your personally identifiable information.</p>
        <h2>Suppliers and Other Service Providers</h2>
        <p>We may use third-party suppliers and service providers to facilitate provision of our professional employer and IT-related services. For example, we may outsource the operations of one or more aspects of our sites to a supplier or service provider who performs services according to our requirements. In all cases, we restrict how these suppliers and other service providers may access, use and disclose information received from our Websites. We do not permit these suppliers and other service providers to access information about you, other than that information necessary for them to complete the services for which they are contracted. Under no circumstances do we allow them to disclose, sell, rent, lend, trade or in any other way transmit data about you to third parties, and we use contractual means to require them to provide appropriate protection for your personally identifiable information and to use it only for the services for which they are contracted.</p>
        <h2>Retention of Information</h2>
<p>We retain personally identifiable information that we collect only so long as reasonably necessary to fulfill the purposes for which it was collected and to meet legal requirements. Please refer to the Comp Care Takers LLC Document Retention and Destruction Policy which provides for the destruction of personally identifiable information after it is no longer needed for the purposes for which it was collected and legal requirements have been met.</p>
<h2>Contact Information</h2>
<p>info@compcaretakerscom.com</p>
      </div>
      
    </section>
    </>
  )
}

export default PrivacyPolicy