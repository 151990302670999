import React from 'react';
import UsImage from '../img/why-us.jpg';

const WhyUsSection = () => {
  return (
    <section id="why-us" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
            <div className="content">
              <h3>For Retirement Communities:</h3>
              <p>Your residents rely on sophisticated technology to connect with their families. They utilize diverse devices and applications, all of which demand consistent connectivity, frequent updates, routine maintenance, and robust protection. Challenges can pop up at any time, be it a forgotten password or a malfunctioning printer.</p>
              <p>Here's what we offer:</p>
              <ul>
                <li>Address challenges that might be too demanding for your in-house staff.</li>
                <li>Offer assistance during times when your team may be unavailable.</li>
                <li>Liaise with various service providers like Comcast and app developers.</li>
                <li>Guarantee that software and devices align with the community's security and privacy standards.</li>
              </ul>
            </div>
          </div>
          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style={{ backgroundImage: `url(${UsImage})` }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >&nbsp;</div>
        </div>
      </div>
    </section>
  );
};

export default WhyUsSection;
